class RequestUtils{
    private static restApiUrl: string = "http://52.175.224.128:90";
    private static uploadStreamServiceUrl: string = "http://52.175.224.128";
    //http://mdcscor-tibeki01:81
    //http://52.175.224.128:81

    private static isDevRequest(): boolean{
        // TODO: This way of checking if the request is a dev request is temporary and it should probably be handled in a different way (perhaps checking if the current logged in user is in some dev db)
        return window.location.hostname === "localhost";
    }

    public static sendRequestToUploadStreamService(url: string, method: string, data: any = null): Promise<any>{
        return this.sendRequestToServer(this.uploadStreamServiceUrl, url, method, data);
    }

    public static sendRequest(url: string, method: string, data: any = null): Promise<any>{
        return this.sendRequestToServer(this.restApiUrl, url, method, data);
    }

    public static sendRequestWithConfigString(url: string, method: string, data: any = null){
        let configString = sessionStorage.getItem("configString");
        if(data == null)
            data = {ConfigParam: configString};
        else
            data.ConfigParam = configString;

        return this.sendRequestToServer(this.restApiUrl, url, method, data);
    }

    private static sendRequestToServer(urlPrefix: string, urlSuffix: string, method: string, data: any){
        return(
            fetch(urlPrefix + urlSuffix, {
                method: method,
                headers: {
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json',
                    'x-ignore': RequestUtils.isDevRequest() + ""
                },
                body: JSON.stringify(data)
            })
        )
    }
}

export { RequestUtils }